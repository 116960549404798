import React from 'react';
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';

import Header from '../include/Header';
import Footer from '../include/Footer';

function NotFound() {

    return (
        <>
            <MetaTags>
                <meta property="og:title" content="404 | 삼해트" />
                <meta name='title' content='404 | 삼해트' />
                <title>404 | 삼해트</title>
            </MetaTags>

            <div className="flex flex-col min-h-screen overflow-hidden">
                <Header />
                <main className="flex-grow">
                    <section className="bg-gradient-to-b from-gray-100 to-white">
                        <div className="max-w-6xl mx-auto px-4 sm:px-6">
                            <div className="pt-32 pb-12 md:pt-40 md:pb-20">
                                <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
                                    <h1 className="h1 mb-4">404</h1>
                                    <p className="text-xl text-gray-600">요청한 페이지가 존재하지 않습니다.</p>
                                </div>
                                <div className="max-w-sm mx-auto">
                                    <div className="flex flex-wrap -mx-3 mt-6">
                                        <div className="w-full px-3">
                                            <Link to="/" className="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">
                                                <button className="btn text-white bg-blue-600 hover:bg-blue-700 w-full">메인 페이지로 이동하기</button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
                <Footer />
            </div>
        </>
    );
}

export default NotFound;