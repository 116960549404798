import React from 'react';
import { Link } from 'react-router-dom'

function Projects_home() {
    return (
        <section className="relative">
            <div className="absolute inset-0 top-1/2 md:mt-24 lg:mt-0 bg-gray-900 pointer-events-none" aria-hidden="true"></div>
            <div className="absolute left-0 right-0 bottom-0 m-auto w-px p-px h-20 bg-gray-200 transform translate-y-1/2"></div>

            <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
                <div className="py-12 md:py-20">
                    <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
                        <h2 className="h2 mb-4">제가 진행한 프로젝트들</h2>
                        <p className="text-xl text-gray-600">제가 진행했거나, 진행중인 <strong>대표</strong> 프로젝트 목록이에요!<br />자세한 사항은 <Link to='/projects' style={{'color':'#0070F4'}}>여기</Link>에서 확인할 수 있어요.</p>
                    </div>
                    <div className="max-w-sm mx-auto grid gap-6 md:grid-cols-2 lg:grid-cols-3 items-start md:max-w-2xl lg:max-w-none">
                        <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
                            <img src='/images/projects/teamht.png' alt='Team HT LOGO' width='64' height='64' rx='32' style={{'borderRadius': '100%'}} />
                            <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">Team HT</h4>
                            <p className="text-gray-600 text-center">Team HT, 디스코드를 더욱 즐겁게.</p>
                            <br />
                            <Link to='projects/teamht'>
                                <button className="btn text-white bg-blue-600 hover:bg-blue-700 shadow">프로젝트 자세히 보기</button>
                            </Link>
                        </div>

                        <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
                            <img src='/images/projects/prforest.png' alt='홍보숲 LOGO' width='64' height='64' rx='32' style={{'borderRadius': '100%'}} />
                            <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">홍보숲</h4>
                            <p className="text-gray-600 text-center">즐겁고 다양하게, 홍보숲입니다.</p>
                            <br />
                            <Link to='projects/prforest'>
                                <button className="btn text-white bg-blue-600 hover:bg-blue-700 shadow">프로젝트 자세히 보기</button>
                            </Link>
                        </div>

                        <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
                            <img src='/images/projects/distube.png' alt='DisTube LOGO' width='64' height='64' rx='32' style={{'borderRadius': '100%'}} />
                            <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">DisTube</h4>
                            <p className="text-gray-600 text-center">DisTube, 디스코드 지식을 알아가는 공간</p>
                            <br />
                            <Link to='projects/distube'>
                                <button className="btn text-white bg-blue-600 hover:bg-blue-700 shadow">프로젝트 자세히 보기</button>
                            </Link>
                        </div>

                        <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
                            <img src='/images/projects/uniquecode.png' alt='UniqueCode LOGO' width='64' height='64' rx='32' style={{'borderRadius': '100%'}} />
                            <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">UniqueCode</h4>
                            <p className="text-gray-600 text-center">당신의 디스코드에 유니크한 재미를 더.</p>
                            <br />
                            <Link to='projects/uniquecode'>
                                <button className="btn text-white bg-blue-600 hover:bg-blue-700 shadow">프로젝트 자세히 보기</button>
                            </Link>
                        </div>

                        <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
                            <img src='/images/projects/discord-korea.png' alt='Discord Korea LOGO' width='64' height='64' rx='32' style={{'borderRadius': '100%'}} />
                            <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">삼해트의 공방</h4>
                            <p className="text-gray-600 text-center">삼해트의 공방입니다.</p>
                            <br />
                            <Link to='projects/discord-korea'>
                                <button className="btn text-white bg-blue-600 hover:bg-blue-700 shadow">프로젝트 자세히 보기</button>
                            </Link>
                        </div>

                        <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
                            <img src='/images/projects/studio-orora.png' alt='Studio Orora LOGO' width='64' height='64' rx='32' style={{'borderRadius': '100%'}} />
                            <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">Studio Orora</h4>
                            <p className="text-gray-600 text-center">Studio Orora입니다.</p>
                            <br />
                            <Link to='projects/studio-orora'>
                                <button className="btn text-white bg-blue-600 hover:bg-blue-700 shadow">프로젝트 자세히 보기</button>
                            </Link>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
}

export default Projects_home;