import React from 'react';
import { Link } from 'react-router-dom';

function ContactInfo() {
    return (
        <section className="relative">
            <div className="absolute left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none -mb-32" aria-hidden="true">
                <svg width="1760" height="518" viewBox="0 0 1760 518" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="illustration-02">
                            <stop stopColor="#FFF" offset="0%" />
                            <stop stopColor="#EAEAEA" offset="77.402%" />
                            <stop stopColor="#DFDFDF" offset="100%" />
                        </linearGradient>
                    </defs>
                    <g transform="translate(0 -3)" fill="url(#illustration-02)" fillRule="evenodd">
                        <circle cx="1630" cy="128" r="128" />
                        <circle cx="178" cy="481" r="40" />
                    </g>
                </svg>
            </div>

            <div className="max-w-6xl mx-auto px-4 sm:px-6">
                <div className="py-12 md:py-20">
                    <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
                        <h2 className="h2 mb-4" id="contact">다양한 정보</h2>
                        <p className="text-xl text-gray-600" data-aos="zoom-y-out">
                            저에 대한 다양한 정보는 <Link className="text-blue-600 hover:underline" to="/about">제 소개</Link>에서 확인하실 수 있어요.<br />
                            저에게 개인적으로 연락하고 싶으시다면<br /><a className="text-blue-600 hover:underline" href='mailto:samsunghappytree123@naver.com'>samsunghappytree123@naver.com</a>으로 연락주세요.<br /><br />
                            이 페이지에 와주셔서 감사해요!
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ContactInfo;