import React from 'react';
import MetaTags from 'react-meta-tags';

import Header from '../include/Header';
import HeroHome from '../include/HeroHome';
import ProjectsHome from '../include/ProjectsHome';
import HomeLastDescription from '../include/HomeLastDescription';
import Footer from '../include/Footer';

function Home() {

    return (
        <>
            <MetaTags>
                <meta property="og:title" content="홈 | 삼해트" />
                <meta name='title' content='홈 | 삼해트' />
                <title>홈 | 삼해트</title>
            </MetaTags>

            <div className="flex flex-col min-h-screen overflow-hidden">
            <Header />
            <main className="flex-grow">
                <HeroHome />
                <hr />
                <ProjectsHome />
                <HomeLastDescription />
            </main>
            <Footer />
            </div>
        </>
    );
}

export default Home;