import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Dropdown from '../utils/Dropdown';

function Header() {

    const [top, setTop] = useState(true);

    useEffect(() => {
        const scrollHandler = () => {
            window.pageYOffset > 10 ? setTop(false) : setTop(true)
        };
        window.addEventListener('scroll', scrollHandler);
        return () => window.removeEventListener('scroll', scrollHandler);
    }, [top]);

    return (
        <header className={`fixed w-full z-30 md:bg-opacity-90 transition duration-300 ease-in-out ${!top && 'bg-white blur shadow-lg'}`}>
            <div className="max-w-6xl mx-auto px-5 sm:px-6">
                <div className="flex items-center justify-between h-16 md:h-20">
                    <div className="flex-shrink-0 mr-4">
                        <Link to="/" className="block" aria-label="삼해트">
                            <img src="/images/favicon.ico" alt='logo' width='40' height='40' style={{'borderRadius': '100%'}} />
                        </Link>
                    </div>
                    <nav className="flex flex-grow">
                        <ul className="flex flex-grow justify-end flex-wrap items-center">
                            <Dropdown title='메뉴'>
                                <li>
                                    <Link to="/about" className="font-medium text-gray-600 hover:text-gray-900 px-5 py-3 flex items-center transition duration-150 ease-in-out">소개</Link>
                                </li>
                                <li>
                                    <Link to="/project" className="font-medium text-gray-600 hover:text-gray-900 px-5 py-3 flex items-center transition duration-150 ease-in-out">프로젝트</Link>
                                </li>
                                <li>
                                    <Link to="/contact" className="font-medium text-gray-600 hover:text-gray-900 px-5 py-3 flex items-center transition duration-150 ease-in-out">연락하기</Link>
                                </li>
                            </Dropdown>
                        </ul>
                    </nav>
                </div>
            </div>
        </header>
    );
}

export default Header;